@import "~draft-js/dist/Draft.css";
@import "~draft-js-image-plugin/lib/plugin.css";
@import "~draft-js-focus-plugin/lib/plugin.css";

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**
 *  Draft editor overrides
 */
.mjx-chtml {
    /**
     hack for getting tex editor to take into account the availabble space
     */
    white-space: pre-wrap !important;
}

.public-DraftEditor-content textarea {
    width: 90%;
    height: 100px;
    border-radius: 10px;
    border: 1px solid rgb(204, 204, 204) !important;
    background-color: #FFEBEE !important;
}

.public-DraftEditor-content textarea + span {
    overflow: auto;
    height: inherit !important;
    width: 90%;
    display: block;
    position: relative !important;
    left: inherit !important;
    transform: inherit !important;
    padding: 10px;
    z-index: 1000000 !important;
    background: ivory !important;
    color: black !important;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.7) 5px 5px 5px;
    top: inherit !important;
}

.DraftEditor-editorContainer img {
    max-width: 700px;
    max-height: 500px;
}

.simple-editor .MuiInput-underline:before {
    border: none !important;
}

.simple-editor .MuiInput-underline:after {
    border: none !important;
}

.RichEditor-blockquote {
    border-left: 5px solid #eee;
    margin: 16px 0;
    padding: 10px 10px;
}

.public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
}
